import React from 'react';
import LayoutSwitcher from './layouts/layout-switcher';
import { Direction } from './containers/direction';
import { widgetWrapper } from './worker/widgetWrapper';
import { createStore } from './widget.store';
import { useReloadOnIdsChange } from './utils/useReloadOnIdsChange';

function Component() {
  useReloadOnIdsChange();

  return (
    <Direction>
      <LayoutSwitcher />
    </Direction>
  );
}

export const DesktopWidgetComponent = widgetWrapper({
  createStore,
  Component,
});
