import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
  IStyleParam,
} from '@wix/tpa-settings';
import {
  TEXT_ALIGNMENT_VALUES,
  PREVIEW_TYPES,
  LAYOUT_STYLE_VALUES,
  MAIN_ACTION_STYLE_TYPES,
  LOAD_MORE_BUTTON_TYPE_VALUES,
  PLAY_TYPE_VALUES,
  CHANNEL_LAYOUT_VALUES,
  EXPANDED_THEME_VALUES,
  MAIN_ITEM_TYPE_VALUES,
  INFO_POSITION_VALUES,
  CHANNEL_VIDEO_LIST_VALUES,
  VIDEO_END_OPTIONS,
  THUMBNAIL_BUTTONS_BEHAVIOR,
  SHOW_MEDIA_TYPE_VALUES,
  SHOW_INFO_VALUES,
  SHOW_EXPANDED_VIDEO_LIST_INFO_VALUES,
  APP_SETTINGS_VERSION_LATEST,
  NAVIGATION_ARROWS_POSITION,
  TITLE_POSITION_VALUES,
  NAVIGATION_ARROWS_BEHAVIOR,
  COMPACT_SHOW_VIDEO_LIST_VALUES,
  THUMBNAIL_SPACING_PRESETS,
  THUMBNAIL_SPACING_VALUE_PER_PRESET,
} from '@wix/wix-vod-constants/app-settings';

const getLayout = (
  params: Parameters<NonNullable<IStyleParam['getDefaultValue']>>[0],
) => {
  const layout: CHANNEL_LAYOUT_VALUES = params.getStyleParamValue(
    stylesParams.channelLayout,
  );

  const isSmallSlider = layout === CHANNEL_LAYOUT_VALUES.SLIDER_SMALL;
  const isBigSlider = layout === CHANNEL_LAYOUT_VALUES.SLIDER_BIG;

  return {
    layout,
    isClassic: layout === CHANNEL_LAYOUT_VALUES.CLASSIC,
    isCompact: layout === CHANNEL_LAYOUT_VALUES.COMPACT,
    isGrid: layout === CHANNEL_LAYOUT_VALUES.GRID,
    isSmallSlider,
    isBigSlider,
    isStrip: layout === CHANNEL_LAYOUT_VALUES.STRIP,
    isSlider: isBigSlider || isSmallSlider,
  };
};

export type IStylesParams = {
  // Colors
  backgroundColor: StyleParamType.Color;
  textColor: StyleParamType.Color;
  buttonsAndLinksColor: StyleParamType.Color;
  mainButtonColor: StyleParamType.Color;
  mainButtonTextColor: StyleParamType.Color;
  mobileMenuTextColor: StyleParamType.Color;
  mobileMenuBackgroundColor: StyleParamType.Color;
  thumbnailButtonsColor: StyleParamType.Color;
  mobileButtonsTextColor: StyleParamType.Color;
  mobileButtonsBackgroundColor: StyleParamType.Color;
  dividersAndArrowsColor: StyleParamType.Color;
  emptyPlayIconEditor: StyleParamType.Color;
  emptyPlayIconSite: StyleParamType.Color;
  emptyPlayerBgEditor: StyleParamType.Color;
  emptyPlayerBgSite: StyleParamType.Color;
  compactMenuBackgroundColor: StyleParamType.Color;
  compactMenuActionsColor: StyleParamType.Color;
  thumbnailOverlayColor: StyleParamType.Color;

  // Fonts
  textFont: StyleParamType.Font;
  titleFont: StyleParamType.Font;
  videoInfoFont: StyleParamType.Font;
  channelId: StyleParamType.Font;
  videoId: StyleParamType.Font;

  // Numbers
  channelLayout: StyleParamType.Number;
  compactShowVideoListType: StyleParamType.Number;
  containerMargins: StyleParamType.Number;
  expandedTheme: StyleParamType.Number;
  layoutStyle: StyleParamType.Number;
  loadMoreButtonType: StyleParamType.Number;
  mainActionStyleType: StyleParamType.Number;
  mainItemOverlayOpacity: StyleParamType.Number;
  mainItemTitlePosition: StyleParamType.Number;
  mainItemType: StyleParamType.Number;
  navigationArrowsBehavior: StyleParamType.Number;
  navigationArrowsPosition: StyleParamType.Number;
  numberOfRows: StyleParamType.Number;
  playType: StyleParamType.Number;
  previewType: StyleParamType.Number;
  settingsVersion: StyleParamType.Number;
  showExpandedVideoListInfo: StyleParamType.Number;
  showInfoAction: StyleParamType.Number;
  showMediaType: StyleParamType.Number;
  sliderHeight: StyleParamType.Number;
  textAlignment: StyleParamType.Number;
  thumbnailButtonsBehavior: StyleParamType.Number;
  thumbnailOverlayOpacity: StyleParamType.Number;
  thumbnailSpacing: StyleParamType.Number;
  videoEndOption: StyleParamType.Number;
  videoInfoPosition: StyleParamType.Number;
  videoListVisibility: StyleParamType.Number;
  videosInRow: StyleParamType.Number;

  // Booleans
  allowComments: StyleParamType.Boolean;
  allowCommentsLive: StyleParamType.Boolean;
  showTitleForMainItem: StyleParamType.Boolean;
  showPublisherForMainItem: StyleParamType.Boolean;
  showChannelTitle: StyleParamType.Boolean;
  showChannelShare: StyleParamType.Boolean;
  showChannelInfo: StyleParamType.Boolean;
  showChannelCategories: StyleParamType.Boolean;
  showSearch: StyleParamType.Boolean;
  showChannelTags: StyleParamType.Boolean;
  showVideoTitle: StyleParamType.Boolean;
  showFullInfo: StyleParamType.Boolean;
  enableVideosSharing: StyleParamType.Boolean;
  autoPlay: StyleParamType.Boolean;
  showSignIn: StyleParamType.Boolean;
  showVideoTitleInList: StyleParamType.Boolean;
  showVideoPublisherInList: StyleParamType.Boolean;
  showVideoDurationInList: StyleParamType.Boolean;
  showVideoDescriptionInList: StyleParamType.Boolean;
  showExpandedChannelTitle: StyleParamType.Boolean;
  showExpandedVideoInfo: StyleParamType.Boolean;
  showExpandedSignIn: StyleParamType.Boolean;
  stretchToFullWidth: StyleParamType.Boolean;
  enableExpandedVideosSharing: StyleParamType.Boolean;
  showExpandedVideoList: StyleParamType.Boolean;
  useOptimalFontSizes: StyleParamType.Boolean;
};

export const stylesParams = createStylesParams<IStylesParams>({
  // Colors
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  textColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  buttonsAndLinksColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  mainButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  mainButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  mobileMenuTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  mobileMenuBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  thumbnailButtonsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  mobileButtonsTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  mobileButtonsBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  dividersAndArrowsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  emptyPlayIconEditor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  emptyPlayIconSite: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  emptyPlayerBgEditor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2'),
  },
  emptyPlayerBgSite: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2'),
  },
  compactMenuBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  compactMenuActionsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  thumbnailOverlayColor: {
    type: StyleParamType.Color,
    getDefaultValue: () => ({
      name: 'thumbnailOverlayColor',
      value: 'rgba(0, 0, 0, 0.4)',
      opacity: 0.4,
    }),
  },

  // Fonts
  textFont: {
    type: StyleParamType.Font,
    getDefaultValue: (params) => {
      const { layout } = getLayout(params);
      const sizeMap: Record<CHANNEL_LAYOUT_VALUES, number> = {
        [CHANNEL_LAYOUT_VALUES.CLASSIC]: 16,
        [CHANNEL_LAYOUT_VALUES.COMPACT]: 16,
        [CHANNEL_LAYOUT_VALUES.GRID]: 14,
        [CHANNEL_LAYOUT_VALUES.SLIDER_SMALL]: 12,
        [CHANNEL_LAYOUT_VALUES.SLIDER_BIG]: 14,
        [CHANNEL_LAYOUT_VALUES.STRIP]: 16,
      };
      const size = sizeMap[layout];
      return wixFontParam('Body-M', { size, preset: 'Custom' })(params);
    },
  },
  titleFont: {
    type: StyleParamType.Font,
    getDefaultValue: (params) => {
      const { layout } = getLayout(params);
      const sizeMap: Record<CHANNEL_LAYOUT_VALUES, number> = {
        [CHANNEL_LAYOUT_VALUES.CLASSIC]: 50,
        [CHANNEL_LAYOUT_VALUES.COMPACT]: 40,
        [CHANNEL_LAYOUT_VALUES.GRID]: 18,
        [CHANNEL_LAYOUT_VALUES.SLIDER_SMALL]: 14,
        [CHANNEL_LAYOUT_VALUES.SLIDER_BIG]: 18,
        [CHANNEL_LAYOUT_VALUES.STRIP]: 58,
      };
      const size = sizeMap[layout];
      return wixFontParam('Title', { size, preset: 'Custom' })(params);
    },
  },
  videoInfoFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-S'),
  },
  // @deprecated use channelId from settingsParams
  channelId: {
    type: StyleParamType.Font,
  },
  // @deprecated use videoId from settingsParams
  videoId: {
    type: StyleParamType.Font,
  },

  // Numbers
  channelLayout: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isEditorX }) =>
      isEditorX
        ? CHANNEL_LAYOUT_VALUES.SLIDER_SMALL
        : CHANNEL_LAYOUT_VALUES.CLASSIC,
  },
  compactShowVideoListType: {
    type: StyleParamType.Number,
    getDefaultValue: () => COMPACT_SHOW_VIDEO_LIST_VALUES.EXPAND_ON_CLICK,
  },
  containerMargins: {
    type: StyleParamType.Number,
    getDefaultValue: (params) => {
      const { isGrid } = getLayout(params);
      return isGrid ? 60 : 0;
    },
  },
  expandedTheme: {
    type: StyleParamType.Number,
    getDefaultValue: () => EXPANDED_THEME_VALUES.DARK,
  },
  layoutStyle: {
    type: StyleParamType.Number,
    getDefaultValue: () => LAYOUT_STYLE_VALUES.SQUARE,
  },
  loadMoreButtonType: {
    type: StyleParamType.Number,
    getDefaultValue: () => LOAD_MORE_BUTTON_TYPE_VALUES.TEXT,
  },
  mainActionStyleType: {
    type: StyleParamType.Number,
    getDefaultValue: (params) => {
      const { isSmallSlider } = getLayout(params);

      if (params.isEditorX) {
        return MAIN_ACTION_STYLE_TYPES.ICON_WITH_TEXT;
      }

      return isSmallSlider
        ? MAIN_ACTION_STYLE_TYPES.ICON
        : MAIN_ACTION_STYLE_TYPES.ICON_WITH_TEXT;
    },
  },
  mainItemOverlayOpacity: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isEditorX }) => (isEditorX ? 40 : 35),
  },
  mainItemTitlePosition: {
    type: StyleParamType.Number,
    getDefaultValue: () => TITLE_POSITION_VALUES.CENTER,
  },
  mainItemType: {
    type: StyleParamType.Number,
    getDefaultValue: () => MAIN_ITEM_TYPE_VALUES.CHANNEL_COVER,
  },
  navigationArrowsBehavior: {
    type: StyleParamType.Number,
    getDefaultValue: (params) => {
      const { isSmallSlider } = getLayout(params);

      return isSmallSlider
        ? NAVIGATION_ARROWS_BEHAVIOR.ALWAYS
        : NAVIGATION_ARROWS_BEHAVIOR.ON_HOVER;
    },
  },
  navigationArrowsPosition: {
    type: StyleParamType.Number,
    getDefaultValue: (params) => {
      const { isSmallSlider } = getLayout(params);

      return isSmallSlider
        ? NAVIGATION_ARROWS_POSITION.OUTSIDE
        : NAVIGATION_ARROWS_POSITION.INSIDE;
    },
  },
  numberOfRows: {
    type: StyleParamType.Number,
    getDefaultValue: (params) => {
      const { isGrid } = getLayout(params);
      return isGrid ? 3 : 1;
    },
  },
  playType: {
    type: StyleParamType.Number,
    getDefaultValue: (params) => {
      const { isClassic, isCompact } = getLayout(params);

      return isClassic || isCompact
        ? PLAY_TYPE_VALUES.IN_FRAME
        : PLAY_TYPE_VALUES.EXPANDED_VIEW;
    },
  },
  previewType: {
    type: StyleParamType.Number,
    getDefaultValue: () => PREVIEW_TYPES.NONE,
  },
  settingsVersion: {
    type: StyleParamType.Number,
    getDefaultValue: () => APP_SETTINGS_VERSION_LATEST,
  },
  showExpandedVideoListInfo: {
    type: StyleParamType.Number,
    getDefaultValue: () => SHOW_EXPANDED_VIDEO_LIST_INFO_VALUES.ON_MOUSE_OVER,
  },
  showInfoAction: {
    type: StyleParamType.Number,
    getDefaultValue: () => SHOW_INFO_VALUES.ON_MOUSE_OVER,
  },
  showMediaType: {
    type: StyleParamType.Number,
    getDefaultValue: () => SHOW_MEDIA_TYPE_VALUES.COVER_IMAGE,
  },
  sliderHeight: {
    type: StyleParamType.Number,
    getDefaultValue: (params) => {
      const { isSmallSlider } = getLayout(params);
      return isSmallSlider ? 388 : 572;
    },
  },
  textAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: (params) => {
      const { isBigSlider } = getLayout(params);
      return isBigSlider
        ? TEXT_ALIGNMENT_VALUES.CENTER
        : TEXT_ALIGNMENT_VALUES.LEFT;
    },
  },
  thumbnailButtonsBehavior: {
    type: StyleParamType.Number,
    getDefaultValue: (params) => {
      const { isSmallSlider } = getLayout(params);
      if (params.isEditorX) {
        return THUMBNAIL_BUTTONS_BEHAVIOR.ON_HOVER;
      }
      return isSmallSlider
        ? THUMBNAIL_BUTTONS_BEHAVIOR.ALWAYS
        : THUMBNAIL_BUTTONS_BEHAVIOR.ON_HOVER;
    },
  },
  thumbnailOverlayOpacity: {
    type: StyleParamType.Number,
    getDefaultValue: () => 40,
  },
  thumbnailSpacing: {
    type: StyleParamType.Number,
    getDefaultValue: (params) => {
      const { isBigSlider } = getLayout(params);

      if (params.isEditorX) {
        return THUMBNAIL_SPACING_VALUE_PER_PRESET[
          THUMBNAIL_SPACING_PRESETS.NARROW
        ];
      }

      if (isBigSlider) {
        return 30;
      }

      return 0;
    },
  },
  videoEndOption: {
    type: StyleParamType.Number,
    getDefaultValue: () => VIDEO_END_OPTIONS.SHOW_VIDEO_COVER,
  },
  videoInfoPosition: {
    type: StyleParamType.Number,
    getDefaultValue: () => INFO_POSITION_VALUES.ON_VIDEOS,
  },
  videoListVisibility: {
    type: StyleParamType.Number,
    getDefaultValue: () => CHANNEL_VIDEO_LIST_VALUES.EXPAND_ON_CLICK,
  },
  videosInRow: {
    type: StyleParamType.Number,
    getDefaultValue: (params) => {
      const { isGrid } = getLayout(params);
      return isGrid ? 3 : 4;
    },
  },

  // Booleans
  allowComments: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  allowCommentsLive: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  showTitleForMainItem: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showPublisherForMainItem: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showChannelTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showChannelShare: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showChannelInfo: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  showChannelCategories: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  showSearch: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showChannelTags: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showVideoTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showFullInfo: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  enableVideosSharing: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  autoPlay: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  showSignIn: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  showVideoTitleInList: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showVideoPublisherInList: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  showVideoDurationInList: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showVideoDescriptionInList: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showExpandedChannelTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  showExpandedVideoInfo: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showExpandedSignIn: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  stretchToFullWidth: {
    type: StyleParamType.Boolean,
    getDefaultValue: (params) => {
      const { isGrid, isSlider, isStrip } = getLayout(params);
      return isGrid || isSlider || isStrip;
    },
  },
  enableExpandedVideosSharing: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showExpandedVideoList: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  useOptimalFontSizes: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
});
export default stylesParams;
