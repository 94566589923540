import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { TFunction, withTranslation } from '@wix/yoshi-flow-editor';

import NavArrow from './images/prev.svg';

import styles from './nav-button.scss';

const navTranslations = {
  next: 'shared.navigation.next-page',
  prev: 'shared.navigation.previous-page',
};

type NavButtonProps = {
  name: 'next' | 'prev';
  onClick: () => void;
  ariaLabel: string;
  disabled: boolean;
  t: TFunction;
};

class GenericNavButton extends React.Component<NavButtonProps> {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    ariaLabel: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    rounded: false,
  };

  render() {
    const { name, disabled, ariaLabel, onClick } = this.props;

    const classNames = classnames(styles['nav-button'], styles[name], {
      [styles.disabled]: disabled,
    });

    return (
      <button
        className={classNames}
        type="button"
        onClick={() => {
          if (!disabled) {
            onClick();
          }
        }}
        aria-label={ariaLabel}
        data-hook={`nav-button-${name}`}
      >
        <NavArrow className={styles['nav-arrow']} />
        <div className={styles['nav-text']}>
          {this.props.t(navTranslations[name])}
        </div>
      </button>
    );
  }
}

export const PrevButton = withTranslation()((props) => {
  return (
    <GenericNavButton
      {...props}
      ariaLabel={props.t('widget.accessibility.prev-videos')}
      name="prev"
    />
  );
});

export const NextButton = withTranslation()((props) => {
  return (
    <GenericNavButton
      {...props}
      ariaLabel={props.t('widget.accessibility.next-videos')}
      name="next"
    />
  );
});
