import {
  useTranslation as useYoshiTranslation,
  useBi as useYoshiBi,
  useSettingsEnvironment as useYoshiSettingsEnvironment,
  useWixSdk as useYoshiWixSdk,
  useEditorSdk as useYoshiEditorSdk,
} from '@wix/yoshi-flow-editor';

export function useTranslation() {
  return useYoshiTranslation();
}

export function useBi() {
  return useYoshiBi();
}

export function useSettingsEnvironment() {
  return useYoshiSettingsEnvironment();
}

export function useWixSdk() {
  return useYoshiWixSdk();
}

export function useEditorSdk() {
  return useYoshiEditorSdk();
}
